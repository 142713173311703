import React, { useCallback, useContext, useEffect, useState } from "react";
import ErrorMessage from "./components/modals/error-sideway";
import SuccessTop from "./components/modals/success-top";
import SessionExpired from "./components/session-expired";
import { useAppSelector } from "./ducks/ducksHook";
import { useAuthService, useUserService } from "./ducks/hooks";
import { selectUserFailed } from "./ducks/slices/user.slice";
import { appConfig } from "@src/utils/api";
import Splash from "@src/components/loading";

interface AuthProviderProps {
  children: React.ReactElement;
}

interface ContextValue {
  maintenance: boolean;
  error: string | null;
  onErrorMessage: (value: string) => void;
  onSuccessMessage: (value: string) => void;
}

export const AuthContext = React.createContext<ContextValue>({} as ContextValue);

const AuthProvider = ({ children }: AuthProviderProps) => {
  const [error, setError] = useState<string | null>(null);
  const { isLoggedIn, onRefreshToken } = useAuthService();
  const isSessionExpired = useAppSelector(selectUserFailed);
  const [success, setSuccess] = useState<string | null>(null);
  const { resetError, fetchUserDetails, fetchUserSubscription } = useUserService();

  const onErrorMessage = useCallback(setError, [error]);
  const onSuccessMessage = useCallback(setSuccess, [success]);

  useEffect(() => {
    if (isLoggedIn) {
      fetchUserDetails();
      fetchUserSubscription();
      onRefreshToken();
    }
  }, [isLoggedIn]);


  const [loaded, setLoaded] = React.useState(false);
  const [maintenance, setMaintenance] = React.useState(false);

  const fetchMaintenance = async (completion: (isMaintenance: boolean) => void) => {
    let maintenance = false
    try {
      const response = await appConfig();
      maintenance = response?.data?.isMaintenance ?? false
    } catch (error) {
      maintenance = false
    } finally {
      completion(maintenance)
    }
  };

  React.useEffect(() => {
    fetchMaintenance((isMaintenance: boolean) => {
      setMaintenance(isMaintenance)
      setLoaded(true);
    })
  }, [setMaintenance]);

  if (!loaded) {
    return <Splash />;
  }

  return (
    <AuthContext.Provider value={{ maintenance, error, onErrorMessage, onSuccessMessage }}>
      {children}
      <SessionExpired visible={isSessionExpired} onPress={resetError} />
      <ErrorMessage
        message={error}
        visible={error !== null}
        onClose={() => setError(null)}
      />
      <SuccessTop
        message={success}
        visible={success !== null}
        onClose={() => setSuccess(null)}
      />
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => {
  return useContext(AuthContext);
};

export default AuthProvider;
