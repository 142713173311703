import { AuthContext } from "@src/AuthProvider";
import { createStackNavigator } from "@react-navigation/stack";
import React, { useContext } from "react";
import { RootStackOptionsValue, RootStackParamList } from "./NavigatorTypes";

// REDUCER
import { useAppSelector } from "@src/ducks/ducksHook";
import { selectAuthLoggedIn } from "@src/ducks/slices/auth.slice";
import { selectedRoutesParams } from "@src/ducks/slices/signsecure.slice";

// COMPONENT SCREENS
import Maintenance from "@src/components/maintenance";
import Forgot from "@src/screens/auth/forgot-password";
import PrivacyPolicy from "@src/screens/auth/privacy-policy";
import ResetPassword from "@src/screens/auth/reset-password";
import SignIn from "@src/screens/auth/signin";
import { default as SignInBackAgain, default as SignInWithFaceID } from "@src/screens/auth/signin-face-id";
import SignUp from "@src/screens/auth/signup";
import SignUpResendVerification from "@src/screens/auth/signup/resend-verification";
import TermsCondition from "@src/screens/auth/terms-condition";
import VerificationCheck from "@src/screens/auth/verification";
import VerificationSuccess from "@src/screens/auth/verification/success";
import Homepage from "@src/screens/drawer-screens";
import TeamVerification from "@src/screens/my-teams/team-verification";
import SignVerification from "@src/screens/verification";
import Document from "@src/screens/affix-document/public-view";
import DeleteAccount from "@src/screens/account/delete-account";
import PromoSignup from "@src/screens/auth/promo-signup";
import PromoRegistrationResult from "@src/screens/auth/promo-signup/payment-result-page";

const RootStack = createStackNavigator<RootStackParamList>();

const RootStackOptions: RootStackOptionsValue = {
  headerShown: false,
  title: "SignSecure",
  cardStyle: { backgroundColor: "white" },
};

const AppRouting = () => {
  const isLoggedIn = useAppSelector(selectAuthLoggedIn);
  const params: any = useAppSelector(selectedRoutesParams);
  const { maintenance } = useContext(AuthContext);

  const screenComponents = React.useMemo(() => {
    if (isLoggedIn) {
      return (
        <>
          <RootStack.Screen name="Home" component={Homepage} initialParams={params} />
          <RootStack.Screen name="verify-success" component={VerificationSuccess} />
          <RootStack.Screen name="delete" component={DeleteAccount} />
          <RootStack.Screen name="privacy-policy" component={PrivacyPolicy} />
          <RootStack.Screen name="terms-condition" component={TermsCondition} />
          <RootStack.Screen name="team-verification" component={TeamVerification} />
        </>
      );
    } else {
      return (
        <>
          <RootStack.Screen name="Signin" component={SignIn} />
          <RootStack.Screen name="SignInWithFaceID" component={SignInWithFaceID} />
          <RootStack.Screen name="SignVerification" component={SignVerification} />
          <RootStack.Screen name="SignInBack" component={SignInBackAgain} />
          <RootStack.Screen name="Signup" component={SignUp} />
          <RootStack.Screen name="PromoSignup" component={PromoSignup} />
          <RootStack.Screen name="Forgot" component={Forgot} />
          <RootStack.Screen name="ResetPassword" component={ResetPassword} />
          <RootStack.Screen name="verify-account" component={VerificationCheck} />
          <RootStack.Screen name="privacy-policy" component={PrivacyPolicy} />
          <RootStack.Screen name="terms-condition" component={TermsCondition} />
          <RootStack.Screen name="team-verification" component={TeamVerification} />
          <RootStack.Screen name="signup-resend-verification" component={SignUpResendVerification} />
          <RootStack.Screen name="document" component={Document} />
          <RootStack.Screen name="payment-success" component={PromoRegistrationResult} />
          <RootStack.Screen name="payment-failed" component={PromoRegistrationResult} />
        </>
      );
    }
  }, [isLoggedIn, params]);

  const screenMaintenanceComponents = React.useMemo(() => {
    return (
      <>
        <RootStack.Screen name="Maintenance" component={Maintenance} />
        <RootStack.Screen name="privacy-policy" component={PrivacyPolicy} />
        <RootStack.Screen name="terms-condition" component={TermsCondition} />
      </>
    );
  }, []);

  return (
    <RootStack.Navigator screenOptions={RootStackOptions}>
      {maintenance && screenMaintenanceComponents}
      {!maintenance && screenComponents}
    </RootStack.Navigator>
  );
};

export default AppRouting;
