import React from "react";
import { Group, Rect, Text } from "react-konva";
import HTMLSignature from "./html-signature";
import QuickSigning from "./quick-signing";
import SignatureSigned from "./signature-signed";

type SignatureProps = {
  field: any;
  onSelect: () => void;
  onQuickSign: () => void;
};

const Signature = (props: SignatureProps) => {
  const { field } = props;
  
  return (
    <React.Fragment>
      <Group
        x={field.config.x}
        y={field.config.y}
        width={field.config.width}
        height={field.config.height}
        onTap={props.onSelect}
        onClick={props.onSelect}
        rotation={field.config.rotation}
      >
        {field?.signature ? 
          <>
            <HTMLSignature
              width={field.config.width}
              documentId={field.documentId}
              signatureDate={field.signatureDate}
              height={field.config.height * 0.3} />
            <SignatureSigned
              x={0}
              y={field.config.height * 0.33}
              width={field.config.width}
              height={field.config.height * 0.67}
              src={field.signature}
            />
          </>
          :
          <>
            <Rect
              fill={`${field.config.fill}30`}
              width={field.config.width}
              height={field.config.height}
              dash={[5, 5]}
              dashOffset={2}
              strokeWidth={2}
              cornerRadius={6.25}
              stroke={field.config.fill}
            />
            <Text
              align="center"
              fill={field.config.fill}
              width={field.config.width}
              fontFamily="DMSans"
              height={field.config.height}
              verticalAlign="middle"
              text={field.textConfig.text}
              fontSize={field.textConfig.fontSize}
            />
          </>}
      </Group>
      <QuickSigning
        field={field}
        onQuickSign={props.onQuickSign}
      />
    </React.Fragment>
  );
};

export default React.memo(Signature);
