import { API_BASE_URL } from "@env";
import { useAppDispatch, useAppSelector } from "@src/ducks/ducksHook";
import { authActions, selectedAuthAccessToken } from "@src/ducks/slices/auth.slice";
import { userActions } from "@src/ducks/slices/user.slice";
import { useCallback, useEffect, useState } from "react";

export type ErrorValue = {
  message: string
}

type optionsProps = {
  responseType?: string;
}

export type FetchGetProps = {
  loading: boolean;
  data: any;
  error: ErrorValue;
  runRequest: (routes: string) => void;
  runReset: () => void;
}

export const useFetchGet = (options: optionsProps = {}) => {
  const dispatch = useAppDispatch();
  const [error, setError] = useState<ErrorValue>({} as ErrorValue);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState<boolean>(false);
  const accessToken = useAppSelector(selectedAuthAccessToken);

  const runRequest = useCallback(
    async (routes: string) => {
      try {
        setLoading(true);
        console.log("`${API_BASE_URL}${routes}`", `${API_BASE_URL}${routes}`);
        const response = await fetch(`${API_BASE_URL}${routes}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        });

        if (response.ok === false) {
          throw await response.json();
        }

        let result = null;

        switch(options.responseType){
        case "text":
          result = await response.text();
          break;
        case "blob":
          result = await response.blob();
          break;
        default:
        case "json":
          result = await response.json();
          break;
        }

        setData(result);
        setLoading(false);
      } catch (err: any) {
        console.log("err", err);
        let message = err.message || err.error || "Something went wrong";
        message = message.replace(/^HttpException:\s*/, "");

        if (message.includes("Unauthorized")) {
          dispatch(userActions.failed({ message }));
          dispatch(authActions.logout());
        }
        setError({ message });
        setLoading(false);
      }
    },
    [accessToken, dispatch]
  );

  const runReset = useCallback(() => {
    setError({} as ErrorValue);
    setData(null);
  },[]);

  useEffect(() => {
    if(loading){
      setError({} as ErrorValue);
    }
  },[loading]);

  return {
    loading,
    data,
    error,
    runRequest,
    runReset,
  } as FetchGetProps;
};
